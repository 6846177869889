import React from 'react';
import { IconButton, IconButtonPage, Layout } from '../../components/onboarding';
import SEAL from '../../images/onboarding/seal.svg';
import X from '../../images/onboarding/x.svg';
import { my } from '../../modules/navigation';

const title = 'Got it! Have you been preapproved?';

export const Preapproved = () => (
  <Layout title={title}>
    <IconButtonPage title={title}>
      <IconButton
        icon={SEAL}
        text="Yes"
        href={`${my}/onboarding/purchase`}
        trackingEvent="discover-preapproved-yes-clicked"
      />

      <IconButton
        icon={X}
        text="Not yet"
        href={`${my}/onboarding/preapproval`}
        trackingEvent="discover-preapproved-notyet-clicked"
      />
    </IconButtonPage>
  </Layout>
);
export default Preapproved;
